<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome! 👋🏻</p>
                  <p class="mb-2">Please sign-in to your account and start the adventure</p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!-- <v-checkbox hide-details label="Remember Me" class="mt-0"> </v-checkbox> -->

                      <!-- forget link -->
                      <a class="ms-3" href="#"> Forgot Password? </a>
                    </div>

                    <v-btn @click="signIn()" block color="primary" type="submit" class="mt-6"> Log in </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text>
                  <p class="text--red">{{ errorMessage }}</p>
                </v-card-text>

                <!-- create new account  -->
                <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">New on our platform?</p>
                  <a href="#"> Create an account </a>
                </v-card-text> -->

                <!-- divider -->
                <!-- <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- socail links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import firebase from 'firebase'

export default {
  data() {
    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      // socialLink: [
      //   {
      //     icon: mdiFacebook,
      //     color: '#4267b2',
      //     colorInDark: '#4267b2',
      //   },
      //   {
      //     icon: mdiTwitter,
      //     color: '#1da1f2',
      //     colorInDark: '#1da1f2',
      //   },
      //   {
      //     icon: mdiGithub,
      //     color: '#272727',
      //     colorInDark: '#fff',
      //   },
      //   {
      //     icon: mdiGoogle,
      //     color: '#db4437',
      //     colorInDark: '#db4437',
      //   },
      // ],
      isPasswordVisible: false,
      email: null,
      password: null,
      errorMessage: null,
    }
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(userCredential => {
          // Signed in
          firebase
            .firestore()
            .collection('users')
            .doc(userCredential.user.uid)
            .get()
            .then(doc => {
              let data = { name: doc.data().name, orgs: doc.data().orgs, uid: doc.id }
              this.$store.commit('setUserData', data)
              sessionStorage.setItem('userData', JSON.stringify(data))
              this.$router.push({ path: '/chooseOrg' })
            })
          // var user = userCredential.user
          // ...
        })
        .catch(error => {
          switch (error.code) {
            case 'auth/invalid-email':
              this.errorMessage = 'Invalid email'
              break
            case 'auth/user-not-found':
              this.errorMessage = 'No account with that email was found'
              break
            case 'auth/wrong-password':
              this.errorMessage = 'Incorrect password'
              break
            default:
              this.errorMessage = 'Email or password was incorrect'
              break
          }
        })
    },
  },
  mounted() {
    // detect whether input fields were auto-filled, if any were, add floating label active class to them
    // autofill overlapping in this situation is a Chrome limitation and a less "hacky" solution doesn't seem to be available as of 10/12/23
    setTimeout(() => {
      const autoFilledElements = document.querySelectorAll('input:-webkit-autofill')
      autoFilledElements.forEach(el => {
        const label = el.parentElement.querySelector('label')
        label.classList.add('v-label--active')

        const labelContainer = el.parentElement.parentElement.parentElement.parentElement
      })
    }, 800)
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
